import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import qs from 'qs';

import { Params as PaginateParams } from '@api/routes/people/paginate/params';
import { people as peopleApi } from 'src/store/api/people';
import { useResponsive } from 'src/hooks/responsive';
import { PERSON_ROUTE } from 'src/constants/routes';
import Header from 'src/components/common/Header';
import Icon from 'src/components/common/Icon';
import Footer from 'src/components/common/Footer';
import LinkButton from 'src/components/common/LinkButton';
import Search from './Search';
import SortDropdown from './SortDropdown';

import desktopBg from 'src/assets/desktop-bg.jpg';
import mobileBg from 'src/assets/mobile-bg.jpg';
import defaultImage from 'src/assets/default-image.jpg';

import css from './Main.module.scss';
import { CAN_USE_DOM } from 'src/constants/other';
import DOMPurify from 'dompurify';

const TEXTS = {
  siteMainText: {
    red: 'הנרצחים והנופלים',
    transparent: 'כתבו לזכרם',
  },
  paragraph: `ילדים וקשישים, נשים וגברים, אזרחים ואנשי כוחות הביטחון וההצלה: אלו הם האנשים שקיפחו את חייהם במתקפת הטרור של חמאס ובמלחמה שפרצה בעקבותיה.`,
  paragraphB: 'כתבו כאן את מילות הפרידה שלכם',
  infoText: 'לקריאה וכתיבת דברי פרידה - לחצו על התמונות',
};

export const ITEMS_PER_PAGE = 102;
type SORT = {
  addedDate: string;
  lastName: string;
};
export type SortModel = { [K in keyof SORT]?: 1 | -1 } | null;
export const DEFAULT_SORT: SortModel = { lastName: 1 };

export type QueryParamsType = {
  search: string;
  sort: SortModel;
  limit: number;
  offset: number;
};

function thousandsSeparator(num: string | number) {
  return parseInt(`${num}`, 10).toLocaleString('en-US', { minimumFractionDigits: 0 });
}

const Main = () => {
  const [isScrolling, setIsScrolling] = React.useState<boolean>(false);
  const [isDesktop] = useResponsive(['DESKTOP']);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = qs.parse(location.search.replace('?', ''));

  const [search, setSearch] = React.useState((queryParams.search as string) || '');
  const [page, setPage] = React.useState(
    queryParams.offset ? Math.ceil(Number(queryParams.offset) / ITEMS_PER_PAGE) + 1 : 1,
  );
  const scrolledToElementRef = React.useRef<HTMLDivElement>(null);
  // const [isFirstView, setIsFirstView] = React.useState(true);
  const [allItemsAmount, setAllItemsAmount] = React.useState(0);

  const [sortModel, setSortModel] = React.useState<SortModel>(
    queryParams.sort ? { [_.keys(queryParams.sort)[0]]: Number(_.values(queryParams.sort)[0]) } : DEFAULT_SORT,
  );

  const [filters, setFilters] = React.useState<QueryParamsType>({
    sort: queryParams.sort ? { [_.keys(queryParams.sort)[0]]: Number(_.values(queryParams.sort)[0]) } : DEFAULT_SORT,
    limit: ITEMS_PER_PAGE,
    offset: Number(queryParams.offset) || 0,
    search: CAN_USE_DOM ? DOMPurify.sanitize(queryParams.search as string) : '' || '',
  });
  const { data, isFetching } = peopleApi.endpoints.paginatePeople.useQuery({ ...(filters as PaginateParams) });

  const items = data?.success ? data.data.items : [];
  const total = data?.success ? data.data.page.total : 0;
  const totalPages = data?.success ? Math.ceil(total / ITEMS_PER_PAGE) : 1;

  const exactSearchSuggestions = _.filter(items, (item) => `${item.firstName} ${item.lastName}`.includes(search));

  React.useEffect(() => {
    const params = qs.stringify(filters);
    navigate(`?${params}`);
  }, [filters]);

  React.useEffect(() => {
    if (data?.success && !search) {
      const lsTotal = localStorage.getItem('total') || 0;

      if (lsTotal) {
        // update LS data if amount changed
        if (Number(lsTotal) !== total) {
          localStorage.setItem('total', `${total}`);
          setAllItemsAmount(total);
        } else {
          setAllItemsAmount(Number(lsTotal));
        }
      } else {
        localStorage.setItem('total', `${total}`);
        setAllItemsAmount(total);
      }
    }

    if (search) {
      // if page will be reload on search - take data from LS only
      const lsTotal = localStorage.getItem('total') || 0;
      if (lsTotal) {
        setAllItemsAmount(Number(lsTotal));
      }
    }
  }, [data?.success, total, search]);

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isDesktop]);

  const triggerOnPageChange = (pageNum: number) => {
    const triggerParams = {
      sort: sortModel ? sortModel : undefined,
      limit: ITEMS_PER_PAGE,
      offset: (pageNum - 1) * ITEMS_PER_PAGE,
      search: search,
    };
    // console.info('=== trigger On Page Change', pageNum, triggerParams);

    setFilters({ ...(triggerParams as QueryParamsType) });
    setPage(pageNum);
    scrollToList();
  };

  const triggerOnSearch = (search: string) => {
    const triggerParams = {
      sort: sortModel ? sortModel : undefined,
      limit: ITEMS_PER_PAGE,
      offset: 0,
      search: search.trim(),
    };
    // console.info('=== trigger On Search', triggerParams);

    setFilters({ ...(triggerParams as QueryParamsType) });
    setSearch(search);
    setPage(1);
  };

  const triggerOnSort = (sort: SortModel) => {
    const triggerParams = {
      sort: sort ? sort : undefined,
      limit: ITEMS_PER_PAGE,
      offset: 0,
      search: search,
    };
    // console.info('=== triggerOnSort', triggerParams);

    setFilters({ ...(triggerParams as QueryParamsType) });
    setSortModel(sort);
    setPage(1);
  };

  const onScroll = () => {
    if (window.pageYOffset > window.innerHeight / 2) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  const onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToList = () => {
    if (scrolledToElementRef.current) {
      scrolledToElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Header isMain />
      <div className={css.mainPage} style={{ backgroundImage: `url(${isDesktop ? desktopBg : mobileBg})` }}>
        <div className={css.intro}>
          <h1 className={css.title}>
            <span className={css.red}>{TEXTS.siteMainText.red}</span>
            <span>{TEXTS.siteMainText.transparent}</span>
          </h1>
          <p className={css.description}>
            {TEXTS.paragraph}
            <b>{TEXTS.paragraphB}</b>
          </p>
          {allItemsAmount > 0 && (
            <p className={css.amount}>{`אלו ${thousandsSeparator(allItemsAmount)} השמות שהותרו לפרסום`}</p>
          )}
          <Search onSearch={triggerOnSearch} filters={filters as unknown as QueryParamsType} />
          <p className={css.info} ref={scrolledToElementRef}>
            {TEXTS.infoText}
          </p>
        </div>
        <div className={css.actions}>
          <SortDropdown initialValue={sortModel} onChange={(v) => triggerOnSort(v)} />
          {isDesktop && <LinkButton />}
        </div>

        <div className={css.list}>
          {_.map(search ? exactSearchSuggestions : items, (item, index) => (
            <Link
              to={PERSON_ROUTE.replace(':id', item._id)}
              className={cn(css.item, isFetching && css.animation)}
              key={item._id}
            >
              <div className={css.imageWrap}>
                <img src={item.imageUrl || defaultImage} alt={`${item.firstName} ${item.lastName}`} />
              </div>
              <div className={css.info}>
                {(item.role || item.firstName || item.lastName) && (
                  <h6 className={css.name}>{`${item.role} ${item.firstName} ${item.lastName}`}</h6>
                )}
                {(item.age || item.city) && (
                  <p className={css.subInfo}>
                    {item.age}
                    {item.age && item.city && ', '} {item.city}
                  </p>
                )}
              </div>
            </Link>
          ))}
        </div>

        {totalPages > 1 && (
          <div className={css.pagination}>
            {page !== 1 && (
              <button type="button" className={css.prevBtn} onClick={() => triggerOnPageChange(page - 1)}>
                <span>{`<<`}</span> לעמוד הקודם
              </button>
            )}
            {totalPages !== page && page !== 1 && <span className={css.devider} />}
            {totalPages !== page && (
              <button type="button" className={css.nextBtn} onClick={() => triggerOnPageChange(page + 1)}>
                לעמוד הבא <span>{`>>`}</span>
              </button>
            )}
          </div>
        )}

        {isScrolling && (
          <button type="button" className={css.scrollTopBtn} onClick={onScrollTop}>
            <Icon type="up" />
          </button>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Main;
